import { withStyles } from "@mui/styles";
import { Component } from "react";
import BuyCoinCard from "./buycoin-card";
import BuyCoinPaypal from "./buycoin-paypal";
import BuyCoinBinance from "./buycoin-binance";
import GetHelpModal from "./../getHelpModal";
import RegisterWallet from "./RegisterWallet";

import { withTranslation } from "react-i18next";

import paypalWebp from "./images/paypal.webp";
import bnbpng from "./images/bnb.png";
import helpjpg from "./images/help.jpg";

const styles = () => ({
    contentWrapper: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "center",
        ["@media (max-width:1000px)"]: {
            flexDirection: "column",
            maxWidth: "85%",
        },
    },
    card: {
        width: "85%",
    },
});

const INITIAL_STATE = {
    fetching: false,
    address: "",
    web3: null,
    provider: null,
    connected: false,
    chainId: 97,
    networkId: 97,
    assets: [],
    showModal: false,
    pendingRequest: false,
    result: null,
    showPaypalModal: false,
    showBinanceModal: false,
    showGetHelpModal: false,
};

class BuyCoin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
        };
    }

    toggleModal() {
        this.setState({ showModal: !this.state.showModal });
    }
    togglePaypalModal() {
        this.setState({ showPaypalModal: !this.state.showPaypalModal });
    }
    openPaypalModal() {
        this.setState({ showPaypalModal: true });
    }
    toggleBinanceModal() {
        this.setState({ showBinanceModal: !this.state.showBinanceModal });
    }
    openBinanceModal() {
        this.setState({ showBinanceModal: true });
    }

    openHelpModal() {
        this.setState({ showGetHelpModal: true });
    }

    toggleGetHelpModal() {
        this.setState({ showGetHelpModal: !this.state.showGetHelpModal });
    }

    render() {
        const { t } = this.props;
        const { classes } = this.props;
        return (
            <>
                <div className={classes.contentWrapper}>
                    <BuyCoinCard
                        className={classes.card}
                        title={t("MODAL_CARD_TITLE_PAYPAL")}
                        description={t("MODAL_CARD_DESCRIPTION_PAYPAL")}
                        action={t("MODAL_CARD_BUTTON_PAYPAL")}
                        image={paypalWebp}
                        onClick={this.openPaypalModal.bind(this)}
                    ></BuyCoinCard>
                    <BuyCoinCard
                        title={t("MODAL_CARD_TITLE_CRYPTO")}
                        description={t("MODAL_CARD_DESCRIPTION_CRYPTO")}
                        action={t("MODAL_CARD_BUTTON_CRYPTO")}
                        image={bnbpng}
                        onClick={this.openBinanceModal.bind(this)}
                    ></BuyCoinCard>
                    <BuyCoinCard
                        title={t("MODAL_CARD_TITLE_HELP")}
                        description={t("MODAL_CARD_DESCRIPTION_HELP")}
                        action={t("MODAL_CARD_BUTTON_HELP")}
                        image={helpjpg}
                        onClick={this.openHelpModal.bind(this)}
                    ></BuyCoinCard>
                </div>
                <BuyCoinPaypal open={this.state.showPaypalModal} onClose={this.togglePaypalModal.bind(this)}></BuyCoinPaypal>
                {window.ethereum ? (
                    <BuyCoinBinance open={this.state.showBinanceModal} onClose={this.toggleBinanceModal.bind(this)}></BuyCoinBinance>
                ) : (
                    <RegisterWallet open={this.state.showBinanceModal} onClose={this.toggleBinanceModal.bind(this)}></RegisterWallet>
                )}
                <GetHelpModal open={this.state.showGetHelpModal} onClose={this.toggleGetHelpModal.bind(this)}></GetHelpModal>
            </>
        );
    }
}

export default withTranslation()(withStyles(styles)(BuyCoin));
