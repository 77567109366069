import { makeStyles } from "@mui/styles";
import { Button, Typography, Modal, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import smartContracts from "./utils/smartcontracts";
import Web3 from "web3";
import checkmark from "./images/checkmark.png";
import { useCoinCount } from "../web3/useCoinCount";
import config from "../../config.json";

const CHAIN_ID = config.CHAIN_ID;

const useStyles = makeStyles({
    modalContent: {
        zIndex: "9999",
    },
    bocWrapper: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        backgroundColor: "black",
        border: "2px solid black",
        boxShadow: 24,
        p: 4,
        backgroundImage: "linear-gradient(to right, #ffcc67 0%,#ff67cb 99%)",
        padding: "3px",
        borderRadius: "5px",
        flex: 1,
        margin: "auto",
    },

    "@media only screen and (max-width: 780px)": {
        bocWrapper: {
            width: "85%",
        },
    },

    boxContainer: {
        borderRadius: "5px",
        backgroundColor: "#0d0f27 !important",
        textAlign: "center",
        padding: "5px",
        paddingBottom: "50px",
    },

    modalTypography: {
        color: "white",
        padding: "20px",
        paddingTop: "25px",
    },

    amountButton: {
        backgroundColor: "none !important",
        backgroundImage: "none !important",
        height: "4em",
        marginLeft: "1em !important",
        marginRight: "1em !important",
    },

    amountText: {
        width: "6em",
    },

    amountContainer: {
        display: "block",
        paddingBottom: "20px",
    },

    input: {
        color: "white !important",
        textAlign: "center !important",
    },

    buyButton: {
        backgroundColor: "none !important",
        backgroundImage: "none !important",
        height: "4em",
        marginLeft: "1em !important",
        marginRight: "1em !important",
        width: "35%",
    },

    closeButton: {
        backgroundColor: "none !important",
        backgroundImage: "none !important",
        height: "4em",
        float: "right",
    },

    importButton: {
        backgroundColor: "none !important",
        backgroundImage: "none !important",
        height: "4em",
        float: "right",
        textTransform: "none !important"
    },

    loader: {
        border: "0.4em solid #f3f3f3",
        borderTop: "0.4em solid #3498db",
        borderRadius: "50%",
        width: "2em",
        height: "2em",
        animation: "$spin 2s linear infinite",
        margin: "auto",
    },

    "@keyframes spin": {
        "0%:": { transform: "rotate(0deg)" },
        "100%": { transform: "rotate(360deg)" },
    },

    checkmark: {
        width: "50%",
    },
});

const Content = (props) => {
    const [FGCAmount, setFGCAmount] = useState(1);
    const [walletMessage, setWalletMessage] = useState(" ");
    const [loader, setLoader] = useState(false);
    const [check, setCheck] = useState(false);
    const [open, setOpen] = useState(props.open);

    const { t } = useTranslation();

    const soldCoins = useCoinCount();

    const statusFlags = {};

    useEffect(() => {
        window.addEventListener("load", async () => {
            try {
                await window.ethereum.enable();
            } catch (error) {}
        });
    }, []);

    useEffect(() => {
        if (props.open) {
            connect();
        }
        setOpen(props.open);
    }, [props.open]);

    const increaseAmount = () => {
        if (FGCAmount < 100000) {
            setFGCAmount(FGCAmount + 1);
            setWalletMessage("");
        } else setWalletMessage(t("WARNING_TEXT_MAX_AMOUNT_BNB"));
    };

    const decreaseAmount = () => {
        if (FGCAmount > 1) setFGCAmount(FGCAmount - 1);
        setWalletMessage("");
    };

    const handleBinanceBuy = async () => {
        if (FGCAmount < config.MAX_AVAILABLE_COINS - soldCoins.coinCount) {
            setLoader(true);
            setWalletMessage(t("LOADING"));
            getCoinOneStep(FGCAmount);
        } else setWalletMessage(t("NOT_ENOUGH_COINS"));
    };

    const handleClose = () => {
        setOpen(false);
        props.onClose();
    };

    const handleAmountChange = (e) => {
        if (e.target.value <= 100000) {
            let value = String(e.target.value);
            let reg = new RegExp("^[0-9]*$");
            if (reg.test(value)) setFGCAmount(Number(e.target.value));
            setWalletMessage("");
        } else setWalletMessage(t("LIMIT_REACHED"));
    };

    const handleBuyAgain = () => {
        setCheck(false);
        setWalletMessage(" ");
        setFGCAmount(1);
    };

    const handleImport = () => {
        watchAssetERC20(smartContracts[config.SMART_CONTRACT]);
    };

    const getChainId = () => {
        return window.ethereum
            .request({ method: "eth_chainId" })
            .then(function (chainId) {
                statusFlags.chainId = parseInt(chainId);
            })
            .catch(function (err) {
                statusFlags.chainId = err.message;
                console.error(err);
            });
    };

    const getAccounts = () => {
        return window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then(function (accounts) {
                if (accounts.length < 1) {
                    statusFlags.account = false;
                } else {
                    statusFlags.account = accounts[0];
                }
                initAccount();
            })
            .catch(function (err) {
                statusFlags.account = false;
            });
    };

    const getContractAvailable = function (contractConfig) {
        let web3 = new Web3(window.ethereum);
        let contract = new web3.eth.Contract(contractConfig.abi, contractConfig.address);

        contract.methods
            .name()
            .call()
            .then(function () {
                statusFlags.contractAvailable = true;
            })
            .catch(function (err) {
                console.error("getContractAvailable", err);
                statusFlags.contractAvailable = false;
            });
    };

    const watchAssetERC20 = function (contractConfig) {
        let web3 = new Web3(window.ethereum);
        let params = {
            type: "ERC20",
            options: {
                address: contractConfig.address,
                symbol: contractConfig.name,
                decimals: web3.utils.unitMap[contractConfig.decimals].toString().length - 1,
                image: window.location + "FGC_Icon.png",
            },
        };
        return window.ethereum
            .request({
                method: "wallet_watchAsset",
                params: params,
            })
            .then(function (result) {
                if (result) {
                    setTimeout(() => {
                        setWalletMessage(t("IMPORT_TOKEN_LOADING"));
                        setLoader(false);
                    }, 1);
                } else {
                    setWalletMessage(t("TRANSACTION_CANCELED"));
                }
            })
            .catch(function (err) {
                console.error("watchAssetERC20", err);
                setWalletMessage(err.message);
            });
    };

    const getBalance = function () {
        if (!statusFlags.account) {
            statusFlags.bnbOwned = 0;
            return;
        }
        let web3 = new Web3(window.ethereum);
        return web3.eth.getBalance(statusFlags.account, function (err, result) {
            if (err) {
                console.log("getBalance", err);
                statusFlags.bnbOwned = err.message;
            } else {
                console.log("getBalance", result, web3.utils.fromWei(result, "ether") + " BNB");
                statusFlags.bnbOwned = web3.utils.fromWei(result, "ether");
            }
        });
    };

    const buyTokens = function (contractConfig, amount = 1) {
        if (!statusFlags.account) {
            statusFlags.coinsOwned = "N/A";
            return;
        }
        let web3 = new Web3(window.ethereum);
        let contract = new web3.eth.Contract(contractConfig.abi, contractConfig.address);

        contract.methods
            .buyTokens(statusFlags.account)
            .send({
                from: statusFlags.account,
                value: config.BNB_VALUE * amount,
            })
            .then(function (result) {
                setWalletMessage(t("PURCHASE_DONE"));
                setCheck(true);
                setLoader(false);
                // initAccount();
            })
            .catch(function (err) {
                if (err.code === 4001 || err.code === 4100 || err.code === 4200 || err.code === 4900 || err.code === 4901)
                    setWalletMessage(t("WALLET_ERROR_" + err.code));
                else setWalletMessage(t("WALLET_ERROR_DEFAULT"));
                setLoader(false);
            });
    };

    const initAccount = () => {
        setTimeout(() => {
            getContractAvailable(smartContracts[config.SMART_CONTRACT]);
        }, 1);
    };

    const connect = () => {
        getAccounts();
    };
    const getCoin = (amount) => {
        return buyTokens(smartContracts[config.SMART_CONTRACTS], amount);
    };

    const getCoinOneStep = async (amount) => {
        await getAccounts();
        if (!statusFlags.account) {
            setLoader(false);
            return setWalletMessage(t("WARNING_TEXT_ACCOUNT_NOT_CONNECTED"));
        }

        await getChainId();
        if (statusFlags.chainId !== CHAIN_ID) {
            setLoader(false);
            return setWalletMessage(t("WARNING_TEXT_WRONG_CHAIN"));
        }

        await getBalance();
        if (statusFlags.bnbOwned <= (config.BNB_VALUE * FGCAmount) / config.BNB_RATE) {
            setLoader(false);
            return setWalletMessage(t("WARNING_TEXT_INSUFFICIENT_FUNDS"));
        }

        await getCoin(amount);
    };

    const classes = useStyles();
    return (
        <>
            <Modal
                className={classes.modalContent}
                open={open}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={classes.bocWrapper} onClick={props.onClick}>
                    <Button type="submit" onClick={handleClose} className={classes.closeButton}>
                        X
                    </Button>
                    <Button type="submit" onClick={handleImport} title={t("IMPORT_TOKEN_TO_WALLET")} className={classes.importButton}>
                        i
                    </Button>
                    <Box className={classes.boxContainer}>
                        <br></br>
                        {check ? (
                            <>
                                <img className={classes.checkmark} src={checkmark} alt="Checkmark"></img>
                            </>
                        ) : (
                            <>
                                <Typography className={classes.modalTypography}>{t("SELECT_FGC_AMOUNT")}</Typography>
                                <div className={classes.amountContainer}></div>
                                <div className={classes.amountContainer}>
                                    <Button onClick={() => decreaseAmount()} className={classes.amountButton} variant="outlined">
                                        -
                                    </Button>
                                    <TextField
                                        inputProps={{
                                            style: {
                                                textAlign: "center",
                                                color: "#fff",
                                            },
                                        }}
                                        className={classes.amountText}
                                        label="Amount"
                                        color="primary"
                                        value={FGCAmount}
                                        focused
                                        onChange={handleAmountChange}
                                    />
                                    <Button onClick={() => increaseAmount()} className={classes.amountButton} variant="outlined">
                                        +
                                    </Button>
                                </div>
                            </>
                        )}
                        <hr></hr>
                        <div>{t("APPROX", { amount: ((config.BNB_VALUE * FGCAmount) / config.BNB_RATE).toFixed(7) })}</div>
                        <div dangerouslySetInnerHTML={{ __html: walletMessage }}></div>
                        {loader ? (
                            <>
                                <br></br>
                                <div className={classes.loader}></div>
                            </>
                        ) : (
                            <></>
                        )}
                        <br></br>
                        <hr></hr>
                        {!check ? (
                            <>
                                <Button type="submit" onClick={handleBinanceBuy} className={classes.buyButton} variant="outlined">
                                    {t("BUY_BUTTON")}
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button type="submit" onClick={handleImport} className={classes.buyButton} variant="outlined">
                                    {t("IMPORT_TOKENS")}
                                </Button>
                                <Button type="submit" onClick={handleBuyAgain} className={classes.buyButton} variant="outlined">
                                    {t("BUY_AGAIN_BUTTON")}
                                </Button>
                            </>
                        )}
                    </Box>
                </div>
            </Modal>
        </>
    );
};

export default Content;
