import { useTranslation } from "react-i18next";
import logoPng from "../../../css/images/logo.png";
import {useEffect} from "react";

function Header() {
    const { t } = useTranslation();

    useEffect(()=>{
        try {
            if (window.ethereum) {
                window.ethereum.on('chainChanged', function(chainId) {
                    console.log("Smart Chain changed to ", chainId, ". Reloading page.")
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                });
            }
        } catch(e) {

        }
    }, [])

    return (
        <header className="header_wrap fixed-top">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg">
                    <a
                        className="navbar-brand page-scroll animation"
                        href="#home_section"
                        data-animation="fadeInDown"
                        data-animation-delay="1s"
                    >
                        <img className="logo_light" src={logoPng} alt="logo" />
                        <img className="logo_dark" src={logoPng} alt="logo" />
                    </a>
                    <button
                        className="navbar-toggler animation"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        data-animation="fadeInDown"
                        data-animation-delay="1.1s"
                    >
                        <span className="ion-android-menu" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav m-auto">
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.1s">
                                <a className="nav-link page-scroll nav_item" href="#home_section">
                                    {t("HEADER_HOME")}
                                </a>
                            </li>
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.2s">
                                <a className="nav-link page-scroll nav_item" href="#video">
                                    {t("HEADER_STORY")}
                                </a>
                            </li>
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.3s">
                                <a className="nav-link page-scroll nav_item" href="#benefit">
                                    {t("HEADER_BENEFIT")}
                                </a>
                            </li>
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.4s">
                                <a className="nav-link page-scroll nav_item" href="#buy">
                                    {t("HEADER_BUY")}
                                </a>
                            </li>
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.5s">
                                <a className="nav-link page-scroll nav_item" href="#about">
                                    {t("HEADER_ABOUT")}
                                </a>
                            </li>
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.6s">
                                <a
                                    className="nav-link page-scroll nav_item"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://fox-god-coin-merch.myspreadshop.co.uk/all"
                                >
                                    {t("HEADER_SHOP")}
                                </a>
                            </li>
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.7s">
                                <a className="nav-link page-scroll nav_item" href="#faq">
                                    {t("HEADER_FAQ")}
                                </a>
                            </li>
                            <li className="animation" data-animation="fadeInDown" data-animation-delay="1.8s">
                                <a className="nav-link page-scroll nav_item" href="#contact">
                                    {t("HEADER_CONTACT")}
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;
