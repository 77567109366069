import * as React from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {Trans, useTranslation} from "react-i18next";

const useStyles = makeStyles({
    closeButton: {
        backgroundColor: "transparent",
        backgroundImage: "none",
    },
    modalContent: {
        overflow: "hidden",
    },
    textContent: {
        color: "white",
        padding: "4em",
        textAlign: "justify",
    },
});

const dialogPaperProps = {
    style: {
        backgroundColor: "#0d0f27",
    },
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PrivacyPolicyDialog(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <div>
            <Dialog
                className={classes.modalContent}
                fullScreen
                open={props.open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={dialogPaperProps}
            >
                <AppBar sx={{ position: "relative", backgroundColor: "#b42839" }}>
                    <Toolbar>
                        <IconButton className={classes.closeButton} edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {t("PRIVACY_POLICY_TITLE")}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Typography sx={{ ml: 2, flex: 1 }} className={classes.textContent} variant="h6" component="div">
                    <Trans
                        i18nKey="PRIVACY_POLICY_CONTENT" // optional -> fallbacks to defaults if not provided
                        defaults="PRIVACY_POLICY_CONTENT" // optional defaultValue
                        values={{ what: 'world'}}
                        components={{ h1: <h1 />, h2: <h2 />, li: <li />, ul: <ul />, ol: <ol />, p: <p />, bold: <strong /> }}
                    />
                </Typography>
            </Dialog>
        </div>
    );
}
