import { useCoinCount } from "../web3/useCoinCount";
import config from "../../config.json";
import CountUp from "react-countup";

function CoinCounter() {
    const { coinCount } = useCoinCount();
    return (
        <CountUp
            start={config.MAX_AVAILABLE_COINS}
            end={config.MAX_AVAILABLE_COINS - coinCount}
            duration={10}
            seperator={" "}
            decimals={0}
            decimal={","}
            separator={"."}
            delay={1}
        ></CountUp>
    );
}

export default CoinCounter;
