import { useTranslation } from "react-i18next";
import footerBgPng from "../../../css/images/footer_bg.png";
import synchromePng from "../../../css/images/Synchrome.png";
import PrivacyPolicyDialog from "./PrivacyPolicyDialog";
import { useState } from "react";
import ConditionsDialog from "./ConditionsDialog";
import CookiePolicyDialog from "./CookiePolicyDialog";

function Footer() {
    const [openPrivacy, setOpenPrivacy] = useState(false);
    const [openConditions, setOpenConditions] = useState(false);
    const [openCookie, setOpenCookie] = useState(false);
    const { t } = useTranslation();

    const handlePrivacyDialogButtonClick = () => {
        setOpenPrivacy(true);
    };

    const handleConditionsButtonClick = () => {
        setOpenConditions(true);
    };

    const handleCookieButtonClick = () => {
        setOpenCookie(true);
    };

    return (
        <footer>
            <div className="top_footer bg_light_dark" data-z-index="1" data-parallax="scroll" data-image-src={footerBgPng}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="footer_logo mb-3 animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                <a href="https://synchrome.it/" target={"_blank"} rel="noreferrer" className="page-scroll">
                                    <img alt="logo" src={synchromePng} />
                                </a>
                            </div>
                            <div className="footer_desc">
                                <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                    {t("FOOTER_DESCRIPTION")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom_footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p className="copyright">{t("FOOTER_COPYRIGHT")}</p>
                        </div>
                        <div className="col-md-6">
                            <ul className="list_none footer_menu">
                                <li>
                                    <a href={"#"} onClick={handleCookieButtonClick}>{t("FOOTER_LINK_COOKIE")}</a>
                                    <CookiePolicyDialog open={openCookie} setOpen={setOpenCookie}></CookiePolicyDialog>
                                </li>
                                <li>
                                    <a href={"#"} onClick={handlePrivacyDialogButtonClick}>{t("FOOTER_LINK_PRIVACY")}</a>
                                    <PrivacyPolicyDialog open={openPrivacy} setOpen={setOpenPrivacy}></PrivacyPolicyDialog>
                                </li>
                                <li>
                                    <a href={"#"} onClick={handleConditionsButtonClick}>{t("FOOTER_LINK_TERMS")}</a>
                                    <ConditionsDialog open={openConditions} setOpen={setOpenConditions}></ConditionsDialog>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
