import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import client_en from "./i18n/locales/en.json";
import Frontpage from "./pages/frontpage/frontpage";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {
        en: {
          translation: client_en
        }
      },
      lng: "en", // if you're using a language detector, do not define the lng option
      fallbackLng: "en",
      interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      }
    });

function App() {
  return (
    <div className="App">
        <Frontpage />
    </div>
  );
}

export default App;
