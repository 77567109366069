import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

//CSS Imports
import './css/animate.css';
import './css/cryptocoins.css';
import './css/style.css';
import './css/responsive.css';
import './css/theme.css';


import { Web3ReactProvider } from '@web3-react/core'
const Web3 = require('web3');
function getWeb3Library(provider) {
    return new Web3(provider)
}

ReactDOM.render(
  <React.StrictMode>
      <Web3ReactProvider getLibrary={getWeb3Library}>
          <App />
      </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
