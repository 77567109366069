import bep20abi from "./bep20.abi";
const supportedChains = [
    {
        name: "Binance Smart Chain",
        short_name: "bsc",
        chain: "smartchain",
        network: "mainnet",
        chain_id: 56,
        network_id: 56,
        rpc_url: "https://bsc-dataseed.binance.org/",
        native_currency: {
            symbol: "BNB",
            name: "BNB",
            decimals: "18",
            contractAddress: "",
            balance: ""
        },
        contracts: {
            "FGC": {
                name: "FGC",
                address: "0xd40fccd6c568c4d9e7a25c3d2967a670f7de0d1a",
                abi: bep20abi,
                decimals: "noether"
            },
            "FGCCrowdsale": {
                "name": "FGCCrowdsale",
                "address": "0xa4d90515dA649410440D45f493dDBdc8Ce4aAB19",
                "abi": [
                    {
                        "inputs": [
                            {
                                "internalType": "address",
                                "name": "beneficiary",
                                "type": "address"
                            }
                        ],
                        "name": "buyTokens",
                        "outputs": [],
                        "stateMutability": "payable",
                        "type": "function"
                    },
                    {
                        "inputs": [],
                        "name": "getRate",
                        "outputs": [
                            {
                                "internalType": "uint256",
                                "name": "",
                                "type": "uint256"
                            }
                        ],
                        "stateMutability": "view",
                        "type": "function"
                    }
                ],
                "decimals": "noether"
            }
        },
    }
];

export default supportedChains;