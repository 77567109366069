import { withStyles } from "@mui/styles";
import { Button, Typography, Modal, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import PayPalButton from "./paypal-button";
import checkmark from "./images/checkmark.png";
import config from "../../config.json";

const styles = () => ({
    modalContent: {
        zIndex: "9999",
    },
    bocWrapper: {
        position: "absolute",
        top: "45%",
        left: "45%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        backgroundColor: "black",
        border: "2px solid black",
        boxShadow: 24,
        p: 4,
        backgroundImage: "linear-gradient(to right, #ffcc67 0%,#ff67cb 99%)",
        padding: "3px",
        borderRadius: "5px",
        flex: 1,
        margin: "5%",
    },
    "@media only screen and (max-width: 780px)": {
        bocWrapper: {
            width: "85%",
        },
    },
    boxContainer: {
        borderRadius: "5px",
        backgroundColor: "#0d0f27 !important",
        textAlign: "center",
        paddingBottom: "20px",
    },
    modalTypography: {
        color: "white",
        padding: "20px",
        paddingTop: "56px",
    },
    amountButton: {
        backgroundColor: "none !important",
        backgroundImage: "none !important",
        height: "4em",
        marginLeft: "1em !important",
        marginRight: "1em !important",
    },
    amountText: {
        width: "6em",
    },
    amountContainer: {
        display: "block",
        paddingBottom: "20px",
    },
    input: {
        color: "white !important",
        textAlign: "center !important",
    },
    warningText: {
        color: "#b42839",
        fontWeight: "900",
    },

    checkmark: {
        width: "50%",
    },

    buyButton: {
        backgroundColor: "none !important",
        backgroundImage: "none !important",
        height: "4em",
        marginLeft: "1em !important",
        marginRight: "1em !important",
        width: "35%",
    },
    closeButton: {
        backgroundColor: "none !important",
        backgroundImage: "none !important",
        height: "4em",
        float: "right",
        display: "block",
    },
});

class BuyCoinPaypal extends Component {
    state = {
        fgcAmount: 1,
        walletID: "",
        warningText: " ",
        displayButton: false,
        transactionDone: false,
    };

    t = this.props.t;

    checkWallet = (walletID) => {
        const regex = new RegExp("^0x[a-fA-F0-9]{40}$");
        return regex.test(walletID);
    };

    increaseAmount = () => {
        if (this.state.fgcAmount < config.MAX_PAYPAL_FGC_AMOUNT) this.setState({ fgcAmount: this.state.fgcAmount + 1, warningText: "" });
        else this.setState({ warningText: this.t("WARNING_TEXT_MAX_AMOUNT") });
    };

    decreaseAmount = () => {
        if (this.state.fgcAmount <= 1) this.setState({ warningText: this.t("WARNING_TEXT_MIN_AMOUNT") });
        else this.setState({ fgcAmount: this.state.fgcAmount - 1, warningText: "" });
    };

    handleAddressChange = (e) => {
        if (this.checkWallet(e.target.value)) {
            this.setState({ walletID: e.target.value, displayButton: this.checkWallet(e.target.value), warningText: "" });
        } else {
            this.setState({
                walletID: e.target.value,
                displayButton: this.checkWallet(e.target.value),
                warningText: this.t("WARNING_TEXT_INVALID_WALLET"),
            });
        }
    };

    onApprove = (_data, actions, status) => {
        this.setState({ warningText: this.t("TRANSACTION_SUCCES_TEXT"), transactionDone: true });
    };

    handleBuyAgain = () => {
        this.setState({ transactionDone: false, warningText: " ", fgcAmount: 1, displayButton: false, walletID: "" });
    };

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        const { classes } = this.props;
        return (
            <>
                <Modal
                    className={classes.modalContent}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className={classes.bocWrapper} onClick={this.props.onClick}>
                        <Button type="submit" onClick={this.handleClose} className={classes.closeButton}>
                            X
                        </Button>
                        <Box className={classes.boxContainer}>
                            {this.state.transactionDone ? (
                                <>
                                    <img className={classes.checkmark} src={checkmark}></img>
                                    <br></br>
                                    <br></br>
                                    <span>{this.state.warningText}</span>
                                    <hr></hr>
                                    <Button type="submit" onClick={this.handleBuyAgain} className={classes.buyButton} variant="outlined">
                                        {this.t("BUY_AGAIN_BUTTON")}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Typography className={classes.modalTypography}>{this.t("SELECT_FGC_AMOUNT")}</Typography>
                                    <div className={classes.amountContainer}>
                                        <TextField
                                            onChange={this.handleAddressChange}
                                            inputProps={{
                                                style: { textAlign: "center", color: "#fff", marginLeft: "1em", marginRight: "1em" },
                                            }}
                                            label={this.t("WALLET_ADRESS")}
                                            color="primary"
                                            value={this.state.walletID}
                                            focused
                                        />
                                    </div>
                                    <div className={classes.amountContainer}>
                                        <Button onClick={() => this.decreaseAmount()} className={classes.amountButton} variant="outlined">
                                            -
                                        </Button>
                                        <TextField
                                            inputProps={{
                                                style: { textAlign: "center", color: "#fff" },
                                            }}
                                            className={classes.amountText}
                                            label={this.t("AMOUNT")}
                                            color="primary"
                                            value={this.state.fgcAmount}
                                            focused
                                        />
                                        <Button onClick={() => this.increaseAmount()} className={classes.amountButton} variant="outlined">
                                            +
                                        </Button>
                                    </div>
                                    <span className={classes.warningText}>{this.state.warningText}</span>
                                    <hr></hr>
                                    <PayPalButton
                                        walletID={this.state.walletID}
                                        amount={this.state.fgcAmount}
                                        onApprove={this.onApprove}
                                        disabled={!this.state.displayButton}
                                    ></PayPalButton>
                                </>
                            )}
                        </Box>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withTranslation()(withStyles(styles)(BuyCoinPaypal));
