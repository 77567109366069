import { useState } from "react";
import { useTranslation } from "react-i18next";

function SectionContact() {
    const { t } = useTranslation();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
    };

    const handleBodyChange = (e) => {
        setBody(e.target.value);
    };

    return (
        <section id="contact" className="contact_section small_pt">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12 offset-lg-2">
                        <div className="title_default_light title_border text-center">
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                {t("SECTION_CONTACT_TITLE")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center small_space">
                    <div className="col-lg-4 col-md-6 offset-lg-2">
                        <div className="bg_light_dark  contact_box_s2 animation" data-animation="fadeInLeft" data-animation-delay="0.1s">
                            <div className="contact_title">
                                <h5 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                    {t("SECTION_CONTACT_BOX_TITLE")}
                                </h5>
                                <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                    {t("SECTION_CONTACT_BOX_PARAGRAPH")}
                                </p>
                            </div>
                            <ul className="list_none contact_info mt-margin">
                                <li className="animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                    <i className="ion-ios-email" />
                                    <div className="contact_detail">
                                        <span>{t("SECTION_CONTACT_BOX_EMAIL_TITLE")}</span>
                                        <p>{t("SECTION_CONTACT_BOX_EMAIL")}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                        <div className="pt-4 pt-md-0 animation" data-animation="fadeInRight" data-animation-delay="0.1s">
                            <div name="enq" className="field_form">
                                <div className="row">
                                    <div className="form-group col-md-12 animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                        <input
                                            type="text"
                                            required="required"
                                            placeholder={t("SECTION_CONTACT_PLACEHOLDER_NAME")}
                                            id="first-name"
                                            className="form-control"
                                            name="name"
                                            onChange={handleNameChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-12 animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                        <input
                                            type="email"
                                            required="required"
                                            placeholder={t("SECTION_CONTACT_PLACEHOLDER_EMAIL")}
                                            id="email"
                                            className="form-control"
                                            name="email"
                                            onChange={handleEmailChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-12 animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                        <input
                                            type="text"
                                            required="required"
                                            placeholder={t("SECTION_CONTACT_PLACEHOLDER_SUBJECT")}
                                            id="subject"
                                            className="form-control"
                                            name="subject"
                                            onChange={handleSubjectChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-12 animation" data-animation="fadeInUp" data-animation-delay="1s">
                                        <textarea
                                            required="required"
                                            placeholder={t("SECTION_CONTACT_PLACEHOLDER_MESSAGE")}
                                            id="description"
                                            className="form-control"
                                            name="message"
                                            rows="2"
                                            onChange={handleBodyChange}
                                        />
                                    </div>
                                    <div className="col-md-12 text-center animation" data-animation="fadeInUp" data-animation-delay="1.2s">
                                        <a
                                            href={
                                                "mailto:info@synchrome.it?subject=" +
                                                subject +
                                                "&body=%0D%0AName: " +
                                                name +
                                                "%0D%0A" +
                                                body
                                            }
                                        >
                                            <button
                                                title={t("SECTION_CONTACT_SUBMIT_PROMPT")}
                                                className="btn btn-default btn-radius btn-block"
                                                name="submit"
                                                value="Submit"
                                            >
                                                {t("SECTION_CONTACT_SUBMIT")} <i className="ion-ios-arrow-thin-right" />
                                            </button>
                                        </a>
                                    </div>
                                    <div className="col-md-12">
                                        <div id="alert-msg" className="alert-msg text-center" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionContact;
