import { withStyles } from "@mui/styles";
import { Button, Typography, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { Component } from "react";
import {Trans, withTranslation} from "react-i18next";
import * as React from "react";
import Paper from "@mui/material/Paper";


const styles = () => ({
    modalContent: {
        zIndex: "9999",
        overflow: "scroll",
    },
    bocWrapper: {
        position: "absolute",
        top: "45%",
        left: "45%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        backgroundColor: "black",
        border: "2px solid black",
        boxShadow: 24,
        p: 4,
        backgroundImage: "linear-gradient(to right, #ffcc67 0%,#ff67cb 99%)",
        padding: "3px",
        borderRadius: "5px",
        flex: 1,
        margin: "5%",
    },
    "@media only screen and (max-width: 780px)": {
        bocWrapper: {
            width: "85%",
        },
    },
    boxContainer: {
        borderRadius: "5px",
        backgroundColor: "#0d0f27 !important",
        textAlign: "center",
        padding: "50px",
    },
    modalTypography: {
        color: "white",
        paddingTop: "20px",
    },
    closeButton: {
        backgroundColor: "none !important",
        backgroundImage: "none !important",
        height: "4em",
        float: "right",
        display: "block",
    },
});

class GetHelpModal extends Component {
    handleClose = () => {
        this.props.onClose();
    };

    render() {
        const { t } = this.props;
        const { classes } = this.props;
        return (
            <>
                <Modal
                    className={classes.modalContent}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className={classes.bocWrapper} onClick={this.props.onClick}>
                        <Button type="submit" onClick={this.handleClose} className={classes.closeButton}>
                            X
                        </Button>
                        <Box className={classes.boxContainer}>
                            <h4 className={classes.modalTypography}>{t("GET_HELP_TITLE")}</h4>
                            <Paper style={{maxHeight: "70vh", width: "100%", background: "transparent", overflow: 'auto', padding: 30}}>
                                <Typography className={classes.modalTypography}>
                                    <Trans
                                        i18nKey="GET_HELP_CONTENT" // optional -> fallbacks to defaults if not provided
                                        defaults="GET_HELP_CONTENT" // optional defaultValue
                                        values={{ what: 'world'}}
                                        components={{ h1: <h1 />, h2: <h2 />, h3: <h3 />, h4: <h4 />, img: <img />, li: <li />, ul: <ul />, ol: <ol />, p: <p />, bold: <strong />, a: <a /> }}
                                    />
                                </Typography>
                            </Paper>
                        </Box>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withTranslation()(withStyles(styles)(GetHelpModal));
