import {Trans, useTranslation} from "react-i18next";
import tokenBgPng from "../../../css/images/token_bg.png";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import * as React from "react";

const YT_RATIO = 0.715;

const useStyles = makeStyles({
    videoContainer: {
        overflow: "hidden",
        position: "relative",
        width: "100%",
    },
});

function SectionTokenSale() {
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        let height = document.getElementById("youtubeFrame").clientWidth * YT_RATIO;
        document.getElementById("youtubeFrame").style.height = String(height) + "px";
    }, []);

    return (
        <section
            id="video"
            className="section_token token_sale bg_light_dark"
            data-z-index="1"
            data-parallax="scroll"
            data-image-src={tokenBgPng}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12">
                        <div className="title_default_light title_border text-center">
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                {t("SECTION_TOKEN_SALE_TITLE")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="title_default_light title_border text_md_center">
                            <div className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                <Trans
                                    i18nKey="SECTION_TOKEN_SALE_STORY_1" // optional -> fallbacks to defaults if not provided
                                    defaults="SECTION_TOKEN_SALE_STORY_1" // optional defaultValue
                                    values={{ what: 'world'}}
                                    components={{ br: <br />, h4: <h4 />, h5: <h5 />, li: <li />, ul: <ul />, ol: <ol />, p: <p />, bold: <strong /> }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className={classes.videoContainer}>
                            <iframe
                                width="100%"
                                id="youtubeFrame"
                                src="https://www.youtube.com/embed/zg3RgAdtUp8?controls=0"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="title_default_light title_border text_md_center">
                            <Trans
                                i18nKey="SECTION_TOKEN_SALE_STORY_2" // optional -> fallbacks to defaults if not provided
                                defaults="SECTION_TOKEN_SALE_STORY_2" // optional defaultValue
                                values={{ what: 'world'}}
                                components={{ br: <br />, h4: <h4 />, h5: <h5 />, li: <li />, ul: <ul />, ol: <ol />, p: <p />, bold: <strong /> }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionTokenSale;
