import config from "../../config.json"
import { getChainData } from "./helpers/utilities"
import { useEffect, useState } from 'react';

export function useWeb3Network() {
    const [web3Network, setWeb3Network] = useState(null);

    useEffect(() => {
        const Web3 = require('web3');
        let chain = getChainData(config.CHAIN_ID);
        const web3 = new Web3(chain.rpc_url);
        setWeb3Network(web3);
    }, []);

    return {
        web3Network: web3Network
    }
}
