import { withStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import { Component } from "react";

const styles = () => ({
    connectButton: {
        backgroundColor: "#0d0f27 !important",
        backgroundImage: "none",
        width: "100%",
        color: "white !important",
        fontWeight: "600 !important",
    },

    connectButtonWrapper: {
        backgroundImage: "linear-gradient(to right, #ffcc67 0%,#ff67cb 99%)",
        marginTop: "10px",
        padding: "3px",
        borderRadius: "5px",
        maxWidth: "50%",
        margin: "auto",
    },

    cardContainerWrapper: {
        backgroundImage: "linear-gradient(to right, #ffcc67 0%,#ff67cb 99%)",
        padding: "3px",
        borderRadius: "5px",
        flex: 1,
        margin: "5%",
        cursor: "pointer",
        "&:hover": {
            backgroundImage: "linear-gradient(to right, #ffcc67 0%,#ff67cb 99%)",
            boxShadow: "0px 0px 28px 1px rgba(252,187,255,0.93)",
        },
    },

    cardContainer: {
        borderRadius: "5px",
        backgroundColor: "#0d0f27 !important",
        textAlign: "center",
        "&:hover": {
            backgroundImage: "linear-gradient(to right, #ffcc67 0%,#ff67cb 99%)",
        },
    },

    cardTypography: {
        color: "white",
    },

    contentWrapper: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "center",
        ["@media (max-width:1000px)"]: {
            flexDirection: "column",
            maxWidth: "50%",
        },
    },

    cardTypographyContainer: {
        padding: "10px",
    },
});

class BuyCoinCard extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.cardContainerWrapper} onClick={this.props.onClick}>
                <div className={classes.cardContainer}>
                    <img src={this.props.image} />
                    <div className={classes.cardTypographyContainer}>
                        <Typography className={classes.cardTypography} variant="h4">
                            {this.props.title}
                        </Typography>
                        <Typography className={classes.cardTypography} variant="p">
                            {this.props.description}
                        </Typography>
                    </div>
                    <div className={classes.connectButtonWrapper}>
                        <Button className={classes.connectButton}>{this.props.action}</Button>
                    </div>
                    <br></br>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(BuyCoinCard);
