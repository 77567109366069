import { useTranslation } from "react-i18next";

function SectionFAQ() {
    const { t } = useTranslation();
    return (
        <section id="faq" className="bg_light_dark">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12 offset-lg-2">
                        <div className="title_default_light title_border text-center">
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                {t("SECTION_FAQ_TITLE")}
                            </h4>
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                {t("SECTION_FAQ_PARAGRAPH1")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row small_space">
                    <div className="col-lg-12 col-md-12">
                        <div className="tab_content">
                            <ul className="nav nav-pills tab_nav_s2 tab_color_white justify-content-center" id="pills-tab" role="tablist">
                                <li className="nav-item animation" data-animation="fadeInUp" data-animation-delay="0.5s">
                                    <a className="active" data-toggle="tab" href="#tab1">&nbsp;</a>
                                </li>
                            </ul>
                            <div className="tab-content half_tab">
                                <div className="tab-pane fade show active" id="tab1" role="tabpanel">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div id="accordion1" className="faq_content">
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                    <div className="card-header" id="headingOne">
                                                        <h6 className="mb-0">
                                                            <a
                                                                data-toggle="collapse"
                                                                href="#collapseOne"
                                                                aria-expanded="true"
                                                                aria-controls="collapseOne"
                                                            >
                                                                {t("SECTION_FAQ_QUESTION1")}
                                                            </a>
                                                        </h6>
                                                    </div>
                                                    <div
                                                        id="collapseOne"
                                                        className="collapse show"
                                                        aria-labelledby="headingOne"
                                                        data-parent="#accordion1"
                                                    >
                                                        <div className="card-body">{t("SECTION_FAQ_ANSWER1")}</div>
                                                    </div>
                                                </div>
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                    <div className="card-header" id="headingTwo">
                                                        <h6 className="mb-0">
                                                            <a
                                                                className="collapsed"
                                                                data-toggle="collapse"
                                                                href="#collapseTwo"
                                                                aria-expanded="false"
                                                                aria-controls="collapseTwo"
                                                            >
                                                                {t("SECTION_FAQ_QUESTION2")}
                                                            </a>
                                                        </h6>
                                                    </div>
                                                    <div
                                                        id="collapseTwo"
                                                        className="collapse"
                                                        aria-labelledby="headingTwo"
                                                        data-parent="#accordion1"
                                                    >
                                                        <div className="card-body">{t("SECTION_FAQ_ANSWER2")}</div>
                                                    </div>
                                                </div>
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                                    <div className="card-header" id="headingThree">
                                                        <h6 className="mb-0">
                                                            <a
                                                                className="collapsed"
                                                                data-toggle="collapse"
                                                                href="#collapseThree"
                                                                aria-expanded="false"
                                                                aria-controls="collapseThree"
                                                            >
                                                                {t("SECTION_FAQ_QUESTION3")}
                                                            </a>
                                                        </h6>
                                                    </div>
                                                    <div
                                                        id="collapseThree"
                                                        className="collapse"
                                                        aria-labelledby="headingThree"
                                                        data-parent="#accordion1"
                                                    >
                                                        <div className="card-body">{t("SECTION_FAQ_ANSWER3")}</div>
                                                    </div>
                                                </div>
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s">
                                                    <div className="card-header" id="headingFour">
                                                        <h6 className="mb-0">
                                                            <a
                                                                className="collapsed"
                                                                data-toggle="collapse"
                                                                href="#collapseFour"
                                                                aria-expanded="false"
                                                                aria-controls="collapseFour"
                                                            >
                                                                {t("SECTION_FAQ_QUESTION4")}
                                                            </a>
                                                        </h6>
                                                    </div>
                                                    <div
                                                        id="collapseFour"
                                                        className="collapse"
                                                        aria-labelledby="headingFour"
                                                        data-parent="#accordion1"
                                                    >
                                                        <div className="card-body">{t("SECTION_FAQ_ANSWER4")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div id="accordion2" className="faq_content">
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                                    <div className="card-header" id="headingFive">
                                                        <h6 className="mb-0">
                                                            <a
                                                                data-toggle="collapse"
                                                                href="#collapseFive"
                                                                aria-expanded="true"
                                                                aria-controls="collapseFive"
                                                            >
                                                                {t("SECTION_FAQ_QUESTION5")}
                                                            </a>
                                                        </h6>
                                                    </div>
                                                    <div
                                                        id="collapseFive"
                                                        className="collapse show"
                                                        aria-labelledby="headingFive"
                                                        data-parent="#accordion2"
                                                    >
                                                        <div className="card-body">{t("SECTION_FAQ_ANSWER5")}</div>
                                                    </div>
                                                </div>
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                                    <div className="card-header" id="headingSix">
                                                        <h6 className="mb-0">
                                                            <a
                                                                className="collapsed"
                                                                data-toggle="collapse"
                                                                href="#collapseSix"
                                                                aria-expanded="false"
                                                                aria-controls="collapseSix"
                                                            >
                                                                {t("SECTION_FAQ_QUESTION6")}
                                                            </a>
                                                        </h6>
                                                    </div>
                                                    <div
                                                        id="collapseSix"
                                                        className="collapse"
                                                        aria-labelledby="headingSix"
                                                        data-parent="#accordion2"
                                                    >
                                                        <div className="card-body">{t("SECTION_FAQ_ANSWER6")}</div>
                                                    </div>
                                                </div>
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                                    <div className="card-header" id="headingSeven">
                                                        <h6 className="mb-0">
                                                            <a
                                                                className="collapsed"
                                                                data-toggle="collapse"
                                                                href="#collapseSeven"
                                                                aria-expanded="false"
                                                                aria-controls="collapseSeven"
                                                            >
                                                                {t("SECTION_FAQ_QUESTION7")}
                                                            </a>
                                                        </h6>
                                                    </div>
                                                    <div
                                                        id="collapseSeven"
                                                        className="collapse"
                                                        aria-labelledby="headingSeven"
                                                        data-parent="#accordion2"
                                                    >
                                                        <div className="card-body">{t("SECTION_FAQ_ANSWER7")}</div>
                                                    </div>
                                                </div>
                                                <div className="card animation" data-animation="fadeInUp" data-animation-delay="1s">
                                                    <div className="card-header" id="headingEight">
                                                        <h6 className="mb-0">
                                                            <a
                                                                className="collapsed"
                                                                data-toggle="collapse"
                                                                href="#collapseEight"
                                                                aria-expanded="false"
                                                                aria-controls="collapseEight"
                                                            >
                                                                {t("SECTION_FAQ_QUESTION8")}
                                                            </a>
                                                        </h6>
                                                    </div>
                                                    <div
                                                        id="collapseEight"
                                                        className="collapse"
                                                        aria-labelledby="headingEight"
                                                        data-parent="#accordion2"
                                                    >
                                                        <div className="card-body">{t("SECTION_FAQ_ANSWER8")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionFAQ;
