import {Trans, useTranslation} from "react-i18next";
import tokenBgPng from "../../../css/images/token_bg.png";
import * as React from "react";



function SectionTokenSale() {
    const { t } = useTranslation();

    return (
        <section
            id="chosen"
            className="section_chosenbg_light_dark"
            data-z-index="1"
            data-parallax="scroll"
            data-image-src={tokenBgPng}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12">
                        <div className="title_default_light title_border text-center">
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                {t("SECTION_CHOSEN_SALE_TITLE")}
                            </h4>
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                {t("SECTION_CHOSEN_SALE_CONTENT_LINE_1")}<br />
                                {t("SECTION_CHOSEN_SALE_CONTENT_LINE_2")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="title_default_light title_border text_md_center">
                            <div className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                <Trans
                                    i18nKey="SECTION_CHOSEN_STORY" // optional -> fallbacks to defaults if not provided
                                    defaults="SECTION_CHOSEN_STORY" // optional defaultValue
                                    values={{ what: 'world'}}
                                    components={{ br: <br />, h4: <h4 />, h5: <h5 />, li: <li />, ul: <ul />, ol: <ol />, p: <p />, bold: <strong /> }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionTokenSale;
