import { useTranslation } from "react-i18next";
import aboutImg2Png from "../../../css/images/about_img2.png";

function SectionAboutUs() {
    const { t } = useTranslation();
    return (
        <section id="about" className="small_pt">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="text_md_center">
                            <img
                                className="animation"
                                data-animation="zoomIn"
                                data-animation-delay="0.2s"
                                src={aboutImg2Png}
                                alt="aboutimg2"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 res_md_mt_30 res_sm_mt_20">
                        <div className="title_default_light title_border">
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                {t("SECTION_ABOUT_US_TITLE")}
                            </h4>
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                {t("SECTION_ABOUT_US_PARAGRAPH1")}
                            </p>
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                {t("SECTION_ABOUT_US_PARAGRAPH2")}
                            </p>
                        </div>
                        <a href="documents/FGC-Whitepaper.pdf" target="_blank">
                            {t("SECTION_ABOUT_US_LINK")} <i className="ion-ios-arrow-thin-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionAboutUs;
