import { useTranslation } from "react-i18next";
import startJpg from "../../../css/images/start.jpg";
import bannerBG2Png from "../../../css/images/banner_bg2.png";
import * as React from "react";
import BuyCoinDialog from "../../../components/buycoin/buycoindialog";
import CoinCounter from "../../../components/coincounter/coincounter";

function SectionHome() {
    const [open, setOpen] = React.useState(false);

    const { t } = useTranslation();
    const handleBuyButtonClick = () => {
        setOpen(true);
    };

    return (
        <section
            id="home_section"
            className="section_banner bg_black_dark"
            data-z-index="1"
            data-parallax="scroll"
            data-image-src={bannerBG2Png}
        >
            <canvas id="banner_canvas" className="transparent_effect fixed" />
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 order-lg-first">
                        <div className="banner_text_s2 text_md_center">
                            <h1 className="animation text-white" data-animation="fadeInUp" data-animation-delay="1.1s">
                                <strong>{t("SECTION_HOME_TITLE_FOX_GOD_COIN_1")}</strong>
                                {t("SECTION_HOME_TITLE_FOX_GOD_COIN_2")}
                                <strong>{t("SECTION_HOME_TITLE_FOX_GOD_COIN_3")}</strong>
                            </h1>
                            <h5 className="animation presale_txt text-white" data-animation="fadeInUp" data-animation-delay="1.3s">
                                {t("SECTION_HOME_CONTENT")}
                                <br />
                                <br />
                                <br />
                                <div
                                    className="gradient_box"
                                    style={{
                                        fontSize: "24px",
                                        padding: "10px",
                                        width: "24ch",
                                        borderRadius: "1em",
                                        textAlign: "center",
                                        display: "flex",
                                    }}
                                >
                                    {t("SECTION_HOME_COINS_LEFT_PREFIX")}
                                    <div style={{ width: "8ch" }}>
                                        <CoinCounter />
                                    </div>
                                    {t("SECTION_HOME_COINS_LEFT")}
                                </div>
                            </h5>
                            <div
                                className="transparent_bg tk_counter_inner m-lg-0 banner_token text-center px-0 animation"
                                data-animation="fadeIn"
                                data-animation-delay="1.4s"
                            >
                                {t("SECTION_HOME_COINS_COUNTDOWN")}
                            </div>
                            <div className="btn_group pt-2 pb-3 animation" data-animation="fadeInUp" data-animation-delay="1.4s">
                                <a className="btn btn-border btn-radius" onClick={handleBuyButtonClick}>
                                    {t("SECTION_HOME_BUY_BUTTON")} <i className="ion-ios-arrow-thin-right"></i>
                                </a>
                                <BuyCoinDialog open={open} setOpen={setOpen} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 order-first">
                        <div
                            className="banner_image_right res_md_mb_50 res_xs_mb_30 animation"
                            data-animation-delay="1.5s"
                            data-animation="fadeInRight"
                        >
                            <img alt="banner_vector2" height="600" src={startJpg} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionHome;
