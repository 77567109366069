import { useTranslation } from "react-i18next";
import appBgPng from "../../../css/images/app_bg.png";
import mobileApp3Png from "../../../css/images/mobile_app3.png";
import GetHelpModal from "../../../components/getHelpModal";
import { useState } from "react";
import BuyCoinDialog from "../../../components/buycoin/buycoindialog";

function SectionMobileApp() {
    const [openHelp, setOpenHelp] = useState(false);
    const [openBuyDialog, setOpenBuyDialog] = useState(false);
    const { t } = useTranslation();

    const handleOnCloseHelp = () => {
        setOpenHelp(!openHelp);
    };

    const handleOnClickHelp = () => {
        setOpenHelp(true);
    };

    const handleBuyButtonClick = () => {
        setOpenBuyDialog(true);
    };
    return (
        <section id="buy" className="bg_light_dark" data-z-index="1" data-parallax="scroll" data-image-src={appBgPng}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12 col-sm-12">
                        <div className="title_default_light title_border text_md_center">
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                {t("SECTION_MOBILE_APP_TITLE")}
                            </h4>
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                {t("SECTION_MOBILE_APP_PARAGRAPH1")}
                            </p>
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                {t("SECTION_MOBILE_APP_PARAGRAPH2")} <a href="#" onClick={handleOnClickHelp}>{t("SECTION_MOBILE_APP_LINK1")}</a>
                            </p>
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                {t("SECTION_MOBILE_APP_PARAGRAPH3")} <a href="#" onClick={handleBuyButtonClick}>{t("SECTION_MOBILE_APP_LINK2")}</a>
                            </p>
                        </div>
                        <div className="btn_group text_md_center animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                            <a onClick={handleBuyButtonClick} className="btn btn-default btn-radius">
                                {t("SECTION_MOBILE_APP_BUTTON1")}
                            </a>

                            <a onClick={handleOnClickHelp} className="btn btn-default btn-radius">
                                {t("SECTION_MOBILE_APP_BUTTON2")}
                            </a>
                        </div>
                    </div>
                    <BuyCoinDialog open={openBuyDialog} setOpen={setOpenBuyDialog} />
                    <GetHelpModal open={openHelp} onClose={handleOnCloseHelp} />
                    <div className="col-lg-5 col-md-12 col-sm-12">
                        <div
                            className="res_md_mt_50 res_sm_mt_30 text-center animation"
                            data-animation="fadeInRight"
                            data-animation-delay="0.2s"
                        >
                            <img src={mobileApp3Png} alt="mobile_app3" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionMobileApp;
