import { useEffect, useState } from 'react';
import { useWeb3Network } from "./useWeb3Network";
import config from "../../config.json"
import { getChainData } from "./helpers/utilities"

export function useCoinCount() {
    const {web3Network} = useWeb3Network();
    const [coinCount, setCoinCount] = useState(
        localStorage.getItem(config.SMART_CONTRACT + '_coinCount') || config.MAX_AVAILABLE_COINS
    );
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        localStorage.setItem(config.SMART_CONTRACT + '_coinCount', coinCount);
    }, [coinCount]);

    useEffect(() => {
        if (web3Network) {
            let chain = getChainData(config.CHAIN_ID);
            let contractConfig = chain.contracts[config.SMART_CONTRACT];
            let contract = new web3Network.eth.Contract(contractConfig.abi , contractConfig.address);
            setTimeout(() => {
                contract.methods.totalSupply().call().then(function(result) {
                    setCoinCount(contractConfig.decimals==="noether"?result : web3Network.utils.fromWei(result, contractConfig.decimals));
                    setLoaded(true);
                }).catch(function(err) {
                    console.error("getTotalSupplyERC20", err)
                });
            },2000);
        }
    }, [web3Network]);
    return {
        coinCount: coinCount,
        loaded: loaded
    }
}