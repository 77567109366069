import { withStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import QRCode from "react-qr-code";

const styles = () => ({
    qrCode: {
        margin: "20px",
    },

    buyButton: {
        backgroundColor: "none !important",
        backgroundImage: "none !important",
        height: "4em",
        width: "70%",
        display: "block",
    },

    blockElement: {
        display: "block",
    },
});

class MetamaskCard extends Component {
    render() {
        const { t } = this.props;
        const { classes } = this.props;
        return (
            <div className={classes.blockElement}>
                <a href="https://metamask.app.link/dapp/foxgodcoin.community/" rel="noreferrer" target="_blank">
                    <QRCode
                        bgColor="black"
                        fgColor="white"
                        value="https://metamask.app.link/dapp/foxgodcoin.community/"
                        title="MetaMask"
                        className={classes.qrCode}
                        size="100"
                    />

                    <Button type="submit" className={classes.buyButton} variant="outlined">
                        {t("METAMASK_CONNECT")}
                    </Button>
                </a>
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles)(MetamaskCard));
