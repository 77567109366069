import React from "react";
import { withTranslation } from "react-i18next";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import config from "../../config.json";

class PayPalButton extends React.Component {
    t = this.props.t;

    handleCreateOrder = (_data, actions) => {
        return actions.order.create({
            intent: "CAPTURE",
            processing_instruction: "ORDER_COMPLETE_ON_PAYMENT_APPROVAL",
            purchase_units: [
                {
                    amount: {
                        currency_code: "USD",
                        value: String(this.props.amount * config.PAYPAL_COST_PER_COIN + config.PAYPAL_FIXED_SERVICE_COST),
                    },
                    description: ""+String(this.props.amount) + " FGC to Wallet: " + (this.props.walletID !== '' ? ("[" + this.props.walletID + "]") : this.t("NO_WALLET_PROVIDED")), 
                },
            ],
        });
    };

    handleClick = (_data, actions) => {
        if (this.checkWallet(this.props.walletID)) this.handleCreateOrder(_data, actions);
        else actions.disable();
    };

    handleApprove = (_data, actions) => {

        console.log("Handle Approve", _data, actions);
        return actions.order.capture().then((details) => {
            console.log("Handle Details", details);
            this.props.onApprove(_data, actions);
        });
    };

    render() {
        return (
            <PayPalScriptProvider
                options={{
                    "client-id": config.PAYPAL_CLIENT_ID,
                    currency: config.PAYPAL_CURRENCY,
                    intent: "capture",
                }}
            >
                <PayPalButtons
                    style={{ layout: "horizontal", tagline: false, shape: "rect", color: "gold" }}
                    createOrder={this.handleCreateOrder}
                    onApprove={this.handleApprove}
                    disabled={this.props.disabled}
                />
            </PayPalScriptProvider>
        );
    }
}

export default withTranslation()(PayPalButton);
