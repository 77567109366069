import { useTranslation } from "react-i18next";
import serviceIcon1Png from "../../../css/images/service_icon1.png";
import serviceIcon2Png from "../../../css/images/service_icon2.png";
import serviceIcon3Png from "../../../css/images/service_icon3.png";
import serviceIcon4Png from "../../../css/images/service_icon4.png";
import serviceIcon5Png from "../../../css/images/service_icon5.png";
import serviceIcon6Png from "../../../css/images/service_icon6.png";

function SectionServices() {
    const { t } = useTranslation();
    return (
        <section id="benefit" className="small_pb small_pt">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12">
                        <div className="title_default_light title_border text-center">
                            <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                                {t("SECTION_SERVICES_TITLE")}
                            </h4>
                            <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                {t("SECTION_SERVICES_CONTENT")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                            <img src={serviceIcon1Png} alt="service_icon1" />
                            <h4>{t("SECTION_SERVICES_SERVICE1_TITLE")}</h4>
                            <p>{t("SECTION_SERVICES_SERVICE1_CONTENT")}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                            <img src={serviceIcon2Png} alt="service_icon2" />
                            <h4>{t("SECTION_SERVICES_SERVICE2_TITLE")}</h4>
                            <p>{t("SECTION_SERVICES_SERVICE2_CONTENT")}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="1s">
                            <img src={serviceIcon3Png} alt="service_icon3" />
                            <h4>{t("SECTION_SERVICES_SERVICE3_TITLE")}</h4>
                            <p>{t("SECTION_SERVICES_SERVICE3_CONTENT")}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="1s">
                            <img src={serviceIcon4Png} alt="service_icon4" />
                            <h4>{t("SECTION_SERVICES_SERVICE4_TITLE")}</h4>
                            <p>{t("SECTION_SERVICES_SERVICE4_CONTENT")}</p>
                        </div>
                    </div>
                    <div className="col-lg-4  col-md-6 col-sm-12">
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="1s">
                            <img src={serviceIcon5Png} alt="service_icon5" />
                            <h4>{t("SECTION_SERVICES_SERVICE5_TITLE")}</h4>
                            <p>{t("SECTION_SERVICES_SERVICE5_CONTENT")}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="box_wrap text-center animation" data-animation="fadeInUp" data-animation-delay="1s">
                            <img src={serviceIcon6Png} alt="service_icon6" />
                            <h4>{t("SECTION_SERVICES_SERVICE6_TITLE")}</h4>
                            <p>{t("SECTION_SERVICES_SERVICE6_CONTENT")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionServices;
