import Header from "../generic/header/header";
import SectionHome from "./sectionHome/sectionHome";
import SectionTokenSale from "./sectionTokenSale/sectionTokenSale";
import SectionChosen from "./sectionChosen/sectionChosen";
import SectionServices from "./sectionServices/sectionServices";
import SectionMobileApp from "./sectionMobileApp/sectionMobileApp";
import SectionAboutUs from "./sectionAboutUs/sectionAboutUs";
import SectionContact from "./sectionContact/sectionContact";
import SectionFAQ from "./sectionFAQ/sectionFAQ";
import Footer from "../generic/footer/footer";

function Frontpage() {
    return (
        <div className="Frontpage">
            <Header />
            <SectionHome />
            <SectionTokenSale />
            <SectionChosen />
            <SectionServices />
            <SectionMobileApp />
            <SectionAboutUs />
            <SectionFAQ />
            <SectionContact />
            <Footer />
        </div>
    );
}

export default Frontpage;
