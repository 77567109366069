import { withStyles } from "@mui/styles";
import { Button, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import TrustWalletCard from "./TrustWalletCard";
import MetamaskCard from "./MetamaskCard";

const styles = () => ({
    modalContent: {
        zIndex: "9999",
    },

    bocWrapper: {
        position: "absolute",
        top: "45%",
        left: "45%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        backgroundColor: "black",
        border: "2px solid black",
        boxShadow: 24,
        p: 4,
        backgroundImage: "linear-gradient(to right, #ffcc67 0%,#ff67cb 99%)",
        padding: "3px",
        borderRadius: "5px",
        flex: 1,
        margin: "5%",
    },

    "@media only screen and (max-width: 780px)": {
        bocWrapper: {
            width: "85%",
        },
    },

    boxContainer: {
        borderRadius: "5px",
        backgroundColor: "#0d0f27 !important",
        textAlign: "center",
        paddingBottom: "20px",
    },

    modalTypography: {
        color: "white",
        padding: "20px",
        paddingTop: "56px",
    },

    amountButton: {
        backgroundColor: "none !important",
        backgroundImage: "none !important",
        height: "4em",
        marginLeft: "1em !important",
        marginRight: "1em !important",
    },

    amountText: {
        width: "6em",
    },

    amountContainer: {
        display: "block",
        paddingBottom: "20px",
    },

    input: {
        color: "white !important",
        textAlign: "center !important",
    },

    warningText: {
        color: "#b42839",
        fontWeight: "900",
    },

    qrCode: {
        margin: "20px",
    },

    qrTable: {
        margin: "auto",
    },

    buyButton: {
        backgroundColor: "none !important",
        backgroundImage: "none !important",
        height: "4em",
        width: "70%",
    },

    closeButton: {
        backgroundColor: "none !important",
        backgroundImage: "none !important",
        height: "4em",
        float: "right",
        display: "block",
    },

    contentWrapper: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "center",
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        ["@media (max-width:1000px)"]: {
            flexDirection: "column",
            maxWidth: "85%",
        },
    },
});

class RegisterWallet extends Component {
    handleClose = () => {
        this.props.onClose();
    };
    render() {
        const { t } = this.props;
        const { classes } = this.props;
        return (
            <>
                <Modal
                    className={classes.modalContent}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className={classes.bocWrapper} onClick={this.props.onClick}>
                        <Button type="submit" onClick={this.handleClose} className={classes.closeButton}>
                            X
                        </Button>
                        <Box className={classes.boxContainer}>
                            <h4 className={classes.modalTypography}>{t("REGISTER_WALLET_TITLE")}</h4>
                            <div className={classes.contentWrapper}>
                                <TrustWalletCard></TrustWalletCard>
                                <MetamaskCard></MetamaskCard>
                            </div>
                        </Box>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withTranslation()(withStyles(styles)(RegisterWallet));
